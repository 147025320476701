
import {
    defineComponent, reactive, PropType, computed,
} from 'vue';
import TrackedItem from '@/domain/TrackedItem';
import useScreenSize from '@/composable/useScreenSize';
import sideBarStore from '@/components/sidebar/store/SidebarStore';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import Permissions from '@/services/permissions/Permissions';

type State = {
    showModal: boolean;
    barcode: string;
}

export default defineComponent({
    name: 'tracked-item-tag-modal',
    props: {
        tags: {
            type: Array as PropType<Array<TrackedItem>>,
            default: undefined,
        },
        title: {
            type: String,
            default: getTitleCaseTranslation('core.domain.itemTags'),
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        manual: {
            type: Boolean,
            required: false,
            default: false,
        },
        override: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['removeTag', 'addBarcode'],
    setup(props, ctx) {
        const { screenWidth } = useScreenSize();
        const sideBar = sideBarStore.getInstance();
        const canManuallyKeyInTags = Permissions.FLOORTRAK.canManuallyKeyInTags() && props.manual;

        const shouldShortenTagText = computed(():boolean => {
            const sideBarIsVisible = sideBar.sideBarVisible;
            if (screenWidth.value <= 1500) {
                return true;
            }
            if (screenWidth.value > 1500 && screenWidth.value < 1800 && sideBarIsVisible) {
                return true;
            }
            return false;
        });

        const tagsButtonText = computed((): string => {
            if (props.override) {
                return getTitleCaseTranslation('core.domain.addTag');
            }
            if (shouldShortenTagText.value) {
                return getTitleCaseTranslation('core.common.tags');
            }
            return `${getTitleCaseTranslation('core.common.tags')}: ${props?.tags?.length}`;
        });

        const state = reactive<State>({
            showModal: false,
            barcode: '',
        });

        function remove(tag: TrackedItem) {
            ctx.emit('removeTag', tag);
        }

        function add(barcode: string) {
            ctx.emit('addBarcode', barcode);
            state.barcode = '';
        }

        function openModal(): void {
            state.showModal = true;
        }

        function closeModal(): void {
            state.showModal = false;
        }

        return {
            state,
            remove,
            add,
            openModal,
            closeModal,
            tagsButtonText,
            getTranslation,
            getTitleCaseTranslation,
            canManuallyKeyInTags,
        };
    },
});
